import {atom, selector, selectorFamily} from 'recoil';

import {BoughtProduct} from 'interfaces/BoughtProduct';
import {SoldProduct} from 'interfaces/SoldProduct';
import {supabase} from 'supabaseClient';
import {SYSTEM_BOUGHT_MESSAGE} from 'utils/constants';

const purchaseSalesActionsCountAtom = atom({key: 'historyActionsCount', default: 0});

export const getBoughtProductsSelector = selectorFamily<any, any>({
  key: 'getBoughtProducts',
  get:
    (user) =>
    async ({get}) => {
      get(purchaseSalesActionsCountAtom);

      const {data, error} = await supabase
        .from('carts')
        .select('bought_at, bought_history')
        .eq('status', 'BOUGHT')
        .eq('owner_id', user!.id);

      if (error) {
        throw error.message;
      }

      const mappedData = data
        .map((cart: any) => {
          return cart.bought_history.map((product: any) => {
            return {
              id: product.id,
              name: product.name,
              price: product.price,
              sizes: product.sizes,
              description: product.description,
              firstImageUrl: product.custom_text_1,
              sellerId: get(getSellerIdSelector(product.seller_name)),
              sellerName: product.seller_name,
              boughtDate: cart.bought_at.slice(0, 10),
            } as BoughtProduct;
          });
        })
        .flat();

      return mappedData;
    },
});

export const getSoldProductsSelector = selectorFamily<any, any>({
  key: 'getSoldProducts',
  get:
    (user) =>
    async ({get}) => {
      get(purchaseSalesActionsCountAtom);

      if (!user) return null;

      const {data, error} = await supabase
        .rpc('get_all_products2')
        .select('*')
        .eq('owner_id', user.id)
        .eq('is_sold', true);

      if (error) {
        throw error.message;
      }

      const mappedData = data.map((product) => {
        const carts = get(getProductSaleDateSelector);
        const boughtDates = carts
          .map((cart: any) => {
            const found = cart.bought_history.find((x: any) => x.id === product.id);
            return found ? cart.bought_at.slice(0, 10) : null;
          })
          .filter(Boolean);
        const buyer = get(getBuyerNameIdSelector(product.id));

        return {
          id: product.id,
          name: product.name,
          price: product.price,
          sizes: product.sizes,
          description: product.description,
          firstImageUrl: product.custom_text_1,
          buyerId: buyer.id,
          buyerName: buyer.name,
          boughtDate: boughtDates,
        } as SoldProduct;
      });

      return mappedData;
    },
});

export const getHasProductSysMsgSelector = selectorFamily<
  any,
  {prodId: number; sellerId: number | null}
>({
  key: 'getHasProductChatSystemMessage',
  get:
    ({prodId, sellerId}) =>
    async () => {
      if (!prodId || !sellerId) return null;
      let hasSysMes: boolean | null = null;

      const {data, error} = await supabase
        .from('chats')
        .select('id')
        .eq('product', prodId)
        .or(`first_participant.eq.${sellerId},second_participant.eq.${sellerId}`);

      if (error) throw error.message;

      if (!data || data.length === 0) {
        console.error('No chat data found');
        return false;
      }

      const chatId = data[0].id;

      const {data: data2, error: error2} = await supabase
        .from('messages')
        .select('text')
        .eq('chat', chatId);

      if (error2) throw error2.message;

      hasSysMes = data2 && data2.some((msg: any) => msg.text === SYSTEM_BOUGHT_MESSAGE);
      return hasSysMes;
    },
});

const getProductSaleDateSelector = selector({
  key: 'getProductSaleDate',
  get: async ({get}) => {
    get(purchaseSalesActionsCountAtom);

    const {data, error} = await supabase
      .from('carts')
      .select('bought_at, bought_history')
      .eq('status', 'BOUGHT');

    if (error) {
      throw error.message;
    }

    return data;
  },
});

const getSellerIdSelector = selectorFamily({
  key: 'getBuyerSellerId',
  get: (nickName) => async () => {
    const {data, error} = await supabase
      .from('profiles')
      .select('user_id')
      .eq('nick_name', nickName)
      .single();

    if (error) throw error.message;

    if (data) return data.user_id;
  },
});

const getBuyerNameIdSelector = selectorFamily({
  key: 'getBuyerNameId',
  get: (productId) => async () => {
    try {
      const {data, error} = await supabase.from('carts').select('*').eq('status', 'BOUGHT');

      if (error) throw error.message;

      const matchedCart = data.find((d: any) =>
        d.bought_history.some((x: any) => x.id === productId),
      );

      if (!matchedCart) throw new Error('Product not found in any cart history');

      const ownerId = matchedCart.owner_id;

      const {data: data2, error: error2} = await supabase
        .from('profiles')
        .select('nick_name')
        .eq('user_id', ownerId)
        .single();

      if (error2) throw error2.message;

      const buyerName = data2 ? data2.nick_name : null;

      return {id: ownerId, name: buyerName};
    } catch (error: any) {
      throw error.message;
    }
  },
});
